import React from "react";
import styled from "styled-components";

import { colors } from "../components/Colors";
import { PageContainer } from "../components/StyledComponents";
import CallToAction from "../components/CallToAction";

const CaseStudyContainer = styled.section`
  width: 100%;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  color: ${colors.softWhite};
`;

const CaseStudyHeader = styled.div`
  text-align: center;
  max-width: 800px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: ${colors.primaryOrange};
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: ${colors.offWhite};
  line-height: 1.5;
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: justify;
  line-height: 1.8;
  color: ${colors.softWhite};
  background: ${({ bg }) => bg || colors.darkGray};
  padding: 40px;

  h2 {
    font-size: 28px;
    color: ${colors.primaryOrange};
    margin-bottom: 15px;
    max-width: 800px;
  }

  p {
    font-size: 16px;
    margin-bottom: 20px;
    max-width: 800px;
  }
`;

const TestimonialSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: ${colors.offWhite};
  background-color: ${colors.darkestGray};
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  
  h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: ${colors.lightGreen};
  }
`;

const TestimonialText = styled.p`
  font-size: 18px;
  font-style: italic;
  margin-bottom: 20px;
  line-height: 1.5;
  max-width: 800px;
`;

const CustomerName = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primaryOrange};
  max-width: 800px;
`;

const DesktopIframeContainer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 80vh;
  border: 2px solid ${colors.primaryOrange};
  border-radius: 10px;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 80vh;
    border: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileImageContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }

  img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
`;

const WebsiteLink = styled.a`
  display: block;
  width: 100%;
  font-size: 16px;
  color: ${colors.lightGreen};
  font-weight: bold;
  margin-top: 20px;
  text-align: center;

  &:hover {
    color: ${colors.green};
  }
`;

const CaseStudy = ({ changePage }) => {
  return (
    <PageContainer>
      <CaseStudyContainer>
        <CaseStudyHeader>
          <Title>Case Study: Clean Choice Pressure Washing</Title>
          <Subtitle>
            A dive into the logo redesign and website development process
            for Clean Choice Pressure Washing.
          </Subtitle>
        </CaseStudyHeader>

        {/* Logo Redesign Section */}
        <Section bg={colors.darkestGray}>
          <h2>Logo Redesign</h2>
          <p>
            Our goal was to modernize Clean Choice Pressure Washing's logo while retaining its classic value. 
            The redesign focuses on simplicity, professionalism, and a style that resonates with both their current and future clientele.
          </p>
          <ImageContainer>
            <img
              src="https://s3.us-east-1.amazonaws.com/tirrell.studio-assets/cleanchoicepw-transformation.jpg"
              alt="Logo Redesign Transformation for Clean Choice Pressure Washing"
            />
          </ImageContainer>
        </Section>

        {/* Website Section */}
        <Section>
          <h2>Website</h2>
          <p>
            We emphasized speed and performance in building a custom site, moving away from the customer’s slow WordPress-based website. 
            The new site built from scratch by Tirrell Studio features a custom "Get a Quote" form for seamless lead generation. While still under construction, it is constantly evolving to enhance user experience.
          </p>

          {/* Desktop Iframe */}
          <DesktopIframeContainer>
            <iframe
              src="https://cleanchoicepw.net"
              title="Clean Choice Pressure Washing Website"
            ></iframe>
          </DesktopIframeContainer>

          {/* Mobile Image */}
          <MobileImageContainer>
            <img
              src="https://s3.us-east-1.amazonaws.com/tirrell.studio-assets/cleanchoicepw-website.png"
              alt="Clean Choice Pressure Washing Website Mobile View"
            />
          </MobileImageContainer>

          {/* Link to Website */}
          <WebsiteLink href="https://cleanchoicepw.net" target="_blank">
            Click here to visit the website
          </WebsiteLink>
        </Section>

        {/* Customer Testimonial Section */}
        <TestimonialSection>
          <h2>What the client has to say</h2>
          <TestimonialText>
            "Tirrell Studio is amazing with building your website. They did an amazing job on my business website, making sure I was pleased with it and answered my questions and was very available in making any changes I needed done to it. I would definitely recommend anyone to them!"
          </TestimonialText>
          <CustomerName>- Isai Calderon, Owner of Clean Choice Pressure Washing</CustomerName>
        </TestimonialSection>
      </CaseStudyContainer>
      <CallToAction changePage={changePage} />
    </PageContainer>
  );
};

export default CaseStudy;