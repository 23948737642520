import React, { useState, useEffect } from "react";
import { Container, Navbar, Nav } from 'react-bootstrap';
import styled from 'styled-components';
import Logo from '../assets/images/logo-white.svg';

import { colors } from './Colors';

const StyledNavbar = styled(Navbar)`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props) => (props.scrolled ? '10px 0' : '20px 0')};
    margin: ${(props) => (props.scrolled ? '0' : '20px 20px')};
    border-radius: ${(props) => (props.scrolled ? '0' : '20px')};
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
    border: ${(props) => (props.scrolled ? 'none' : '2px solid #555555')};
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.3s ease-in-out;
    z-index: 1000;

    @media (max-width: 768px) {
        padding: 10px 0;
        margin: 0;
        border: none;
        border-radius: 0;
    }
`;

const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogoImg = styled.img`
    height: 50px;
    margin-right: 10px;
`;

const StyledNavLink = styled(Nav.Link)`
    color: ${(props) => (props.isActive ? colors.lightGreen : colors.softWhite)} !important;
    border-bottom: ${(props) => (props.isActive ? `1px solid ${colors.lightGreen}` : 'none')};

    &:hover {
        color: ${(props) => (props.isActive ? colors.green : `${colors.white} !important`)};
    }
`;

export default function Header({
    changePage,
    activePage
}) {
    const [scrolled, setScrolled] = useState(false);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to handle closing of the navbar after clicking a link
    const handleLinkClick = (page) => {
        changePage(page);
        setExpanded(false);  // Collapse the menu after selecting a page
    };

    return (
        <StyledNavbar bg="dark" variant="dark" expand="lg" sticky="top" scrolled={scrolled} expanded={expanded}>
            <NavbarContainer>
                <Navbar.Brand onClick={() => handleLinkClick('home')} className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <LogoImg src={Logo} alt="Tirrell Studio" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <StyledNavLink onClick={() => handleLinkClick('home')} isActive={activePage === 'home'}>Home</StyledNavLink>
                        <StyledNavLink onClick={() => handleLinkClick('services')} isActive={activePage === 'services'}>Services</StyledNavLink>
                        <StyledNavLink onClick={() => handleLinkClick('about')} isActive={activePage === 'about'}>About Us</StyledNavLink>
                        <StyledNavLink onClick={() => handleLinkClick('casestudy')} isActive={activePage === 'casestudy'}>Case Study</StyledNavLink>
                        <StyledNavLink onClick={() => handleLinkClick('contact')} isActive={activePage === 'contact'}>Contact</StyledNavLink>
                    </Nav>
                </Navbar.Collapse>
            </NavbarContainer>
        </StyledNavbar>
    );
}