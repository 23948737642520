import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import "altcha";

const Altcha = forwardRef(
  ({ onStateChange }, ref) => {
    const widgetRef = useRef(null);
    const [value, setValue] = useState(null);

    useImperativeHandle(ref, () => {
      return {
        get value() {
          return value;
        },
      };
    }, [value]);

    useEffect(() => {
      const handleStateChange = (ev) => {
        if ("detail" in ev) {
          setValue(ev.detail.payload || null);
          onStateChange?.(ev);
        }
      };

      const { current } = widgetRef;

      if (current) {
        current.addEventListener("statechange", handleStateChange);
        return () =>
          current.removeEventListener("statechange", handleStateChange);
      }
    }, [onStateChange]);

    return (
      <altcha-widget
        ref={widgetRef}
        hidelogo
        hidefooter
        style={{ width: "50%" }}
        challengeurl="https://api.tirrell.studio/altchaChallenge"
      ></altcha-widget>
    );
  },
);

export default Altcha;
